import {API} from '../Constants';

// helpers
import { ls } from '../Localstorage';

const isDevelopmentMode = process.env.NODE_ENV !== 'production';

const request = async (endpoint, data, isFile) => {
	const url = `${API.url}${endpoint}`;
	const headers = new Headers();
	if (!isFile) headers.append('Content-Type', 'application/json');
	const token = ls('token');
	if (token)
		headers.append('Authorization', `Bearer ${token}`);
	const options = {
		method:'POST',
		headers,
		body: data ? (isFile ? data : JSON.stringify(data)) : null
	};
	const response = await fetch(url, options);
	if (response.status === 200) {
		if (isDevelopmentMode) console.log(response);
		try {
			const json = await response.json();
			if (isDevelopmentMode) console.log(json);
			return json;
		} catch (ex) {
			return null;
		}
	}
	console.log('error:', response);
	throw await response.json();
};

const Http = {
	request
};

export default Http;